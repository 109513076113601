import { buildCollection, buildProperty, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";

type homePageSlide = {
    image: string
    heading: string
    paragraph: string
}

type socialLink = {
    title: string
    iconName: string
    action: string
}


type homePage = {
    backgroundImage: string
    slides: Array<homePageSlide>
    community: EntityReference
    communitySubDomain: string
    communityCalyxId: number
    socialLinks: Array<socialLink>
    user: string
    createdAt: Date
    updatedAt: Date
}

export const homePageCollection = buildCollection<homePage>({
    name: "Home Pages",
    singularName: "Home Page",
    path: "cms_pages_home",
    icon: "Home",
    properties: {
        backgroundImage: {
            name: "Background Image",
            dataType: "string",
            description: "Image to show in the background of the page",
            storage: {
                storagePath: "cms/pages/home/",
                acceptedFiles: ["image/*"]
            }
        },
        slides: buildProperty({
            dataType: "array",
            name: "Slides",
            of: {
                dataType: "map",
                properties: {
                    image: {
                        name: "Slide Image",
                        dataType: "string",
                        description: "Image to show in the background of this Slide",
                        storage: {
                            storagePath: "cms/pages/home/",
                            acceptedFiles: ["image/*"]
                        }
                    },
                    heading: {
                        name: "Heading",
                        dataType: "string"
                    },
                    paragraph: {
                        name: "Paragraph",
                        dataType: "string",
                        multiline: true
                    }
                }
            },
            expanded: true
        }),
        socialLinks: buildProperty({
            dataType: "array",
            name: "Social Links",
            of: {
                dataType: "map",
                properties: {
                    title: {
                        name: "Title",
                        dataType: "string",
                    },
                    iconName: {
                        name: "Icon Name",
                        dataType: "string"
                    },
                    action: {
                        name: "Action",
                        dataType: "string"
                    }
                }
            },
            expanded: true
        }),
        community: {
            dataType: "reference",
            name: "Community",
            path: "communities",
            validation: { required: true },
        },
        communitySubDomain: {
            name: "Community Sub Domain",
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        user: () => {
            const user = getCurrentUser();
            return ({
                name: "Created  or Updated By User",
                dataType: "string",
                disabled: {
                    hidden: true
                },
                defaultValue: user?.email?.toString(),
            });
        },
        createdAt: {
            name: "Created at",
            dataType: "date",
            disabled: true
        },
        updatedAt: {
            name: "Updated at",
            dataType: "date",
            disabled: true
        }
    },
    group: "Pages",
    callbacks: commonCallbacks
});
