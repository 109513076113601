import { buildCollection, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";

type thankYouNote = {
    title: string
    note: string
    name: string
    designation: string
    photo: string
    signature: string
    userType: string
    community: EntityReference
    communityCalyxId: number
    department: EntityReference
    departmentCalyxId: number
    user: string
    expiresOn: Date
    createdAt: Date
    updatedAt: Date
}

export const thankYouNoteCollection = buildCollection<thankYouNote>({
    name: "Email Digest Thank You Notes",
    singularName: "Email Digest Thank You Notes",
    path: "cms_email_digest_thank_you_notes",
    icon: 'Email',
    properties: {
        title: {
            name: "Title",
            description: "Title of the Note",
            validation: { required: true },
            dataType: "string"
        },
        note: {
            name: "Note",
            validation: { required: true },
            dataType: "string",
            multiline: true
        },
        name: {
            name: "Director's Name",
            description: "Director's Name",
            dataType: "string"
        },
        designation: {
            name: "Designation",
            description: "Director's Designation",
            dataType: "string"
        },
        photo:{
            name: "Photo",
            dataType: "string",
            storage: {
                storagePath: "cms/email_digest_and_newsletter/director_photos/",
                acceptedFiles: ["image/*"]
            }
        },
        signature: {
            name: "Signature",
            dataType: "string",
            storage: {
                storagePath: "cms/email_digest_and_newsletter/director_signatures/",
                acceptedFiles: ["image/*"]
            }
        },
        userType: {
            name: "User Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
                student: "Student",
                alumni: "Alumni",
                both: "Both"
            },
            defaultValue: "Both"
        },
        community: {
            dataType: "reference",
            name: "Community",
            path: "communities",
            validation: { required: true },
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        department: {
            dataType: "reference",
            name: "Department",
            path: "departments"
        },
        departmentCalyxId: {
            name: "Department Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        user: () => {
            const user = getCurrentUser();
            return ({
                name: "Created  or Updated By User",
                dataType: "string",
                disabled: {
                    hidden: true
                },
                defaultValue: user?.email?.toString(),
            });
        },
        expiresOn: {
            name: "Expires on",
            dataType: "date"
        },
        createdAt: {
            name: "Created at",
            dataType: "date",
            disabled: true
        },
        updatedAt: {
            name: "Updated at",
            dataType: "date",
            disabled: true
        }
    },
    group: "Email Digest",
    callbacks: commonCallbacks
});
