import { buildCollection, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";


type column = {
    heading: string,
    content: string
}
type collegeRankingAndStats = {
    heading: string
    column1: column
    column2: column
    column3: column
    column4: column

}

type CDELandingPage = {
    collegeRankingAndStats: collegeRankingAndStats
    community: EntityReference
    communitySubDomain: string
    communityCalyxId: number
    user: string
    createdAt: Date
    updatedAt: Date
}

export const CDELandingPageCollection = buildCollection<CDELandingPage>({
    name: "CDE Landing Pages",
    singularName: "CDE Landing Page",
    path: "cms_pages_cde_landing_page",
    icon: "Description",
    properties: {
        collegeRankingAndStats: {
            name: "College Ranking And Stats",
            dataType: "map",
            description: "College Ranking And Stats",
            properties: {
                heading: {
                    name: "Heading",
                    dataType: "string",
                    validation: { required: true },
                },
                column1: {
                    name: "Column 1",
                    dataType: "map",
                    properties: {
                        heading: {
                            name: "Heading",
                            dataType: "string",
                            validation: { required: true },
                        },
                        content: {
                            name: "Content",
                            dataType: "string",
                            validation: { required: true },
                        }
                    }
                },
                column2: {
                    name: "Column 2",
                    dataType: "map",
                    properties: {
                        heading: {
                            name: "Heading",
                            dataType: "string",
                            validation: { required: true },
                        },
                        content: {
                            name: "Content",
                            dataType: "string",
                            validation: { required: true },
                        }
                    }
                },
                column3: {
                    name: "Column 3",
                    dataType: "map",
                    properties: {
                        heading: {
                            name: "Heading",
                            dataType: "string",
                            validation: { required: true },
                        },
                        content: {
                            name: "Content",
                            dataType: "string",
                            validation: { required: true },
                        }
                    }
                },
                column4: {
                    name: "Column 4",
                    dataType: "map",
                    properties: {
                        heading: {
                            name: "Heading",
                            dataType: "string",
                            validation: { required: true },
                        },
                        content: {
                            name: "Content",
                            dataType: "string",
                            validation: { required: true },
                        }
                    }
                }
            }
        },
        community: {
            dataType: "reference",
            name: "Community",
            path: "communities",
            validation: { required: true },
        },
        communitySubDomain: {
            name: "Community Sub Domain",
            dataType: "string",
            disabled: {
                hidden: true
            }
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        user: () => {
            const user = getCurrentUser();
            return ({
                name: "Created  or Updated By User",
                dataType: "string",
                disabled: {
                    hidden: true
                },
                defaultValue: user?.email?.toString(),
            });
        },
        createdAt: {
            name: "Created at",
            dataType: "date",
            disabled: true
        },
        updatedAt: {
            name: "Updated at",
            dataType: "date",
            disabled: true
        }
    },
    group: "Pages",
    callbacks: commonCallbacks
});
