import { buildEntityCallbacks } from "firecms";
import { getDocumentData } from "../firebase/firebase";

export const commonCallbacks = buildEntityCallbacks({
    onPreSave: async ({
                          collection,
                          values,
                      }) => {
        if (values.community) {
            const communityData = await getDocumentData("communities", values.community.id)
            values.communityCalyxId = communityData?.calyxId;
            if (collection.group == "Pages") {
                values.communitySubDomain = communityData?.subDomain;
            }
        }
        if (values.department) {
            const departmentData = await getDocumentData("departments", values.department.id)
            values.departmentCalyxId = departmentData?.calyxId;
        }
        if (!values.createdAt) {
            values.createdAt = new Date();
        }
        values.updatedAt = new Date();
        return values;
    },
});
