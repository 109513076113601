import { initializeApp } from "firebase/app";
import { getAuth, NextOrObserver, onAuthStateChanged, signInWithCustomToken, User } from "firebase/auth";
import { collection, doc, getDoc, getDocs, getFirestore, limit, query, where } from "firebase/firestore";

import firebaseConfig from "./firebase-creds.json";

export const firebaseApp = initializeApp(firebaseConfig);


const auth = getAuth(firebaseApp);
const firestoreDB = getFirestore(firebaseApp);

export function isLoggedIn() {
    return !!auth.currentUser;
}

export function getCurrentUser() {
    return auth.currentUser;
}

export function signIn(token: string) {
    return signInWithCustomToken(auth, token);
}

export function checkAuthStateChange(observer: NextOrObserver<User>) {
    onAuthStateChanged(auth, observer);
}

export function checkAuthState(observer: NextOrObserver<User>) {
    return onAuthStateChanged(auth, observer);
}

export async function getCommunityData(communityId: number) {
    const q = query(collection(firestoreDB, "communities"), where("calyxId", "==", communityId), limit(1));
    const querySnapshot = await getDocs(q);
    return querySnapshot.docs[0].data();
}

export async function getDocumentData(path: string, id: string) {
    const docRef = doc(firestoreDB, path, id);
    const document = await getDoc(docRef);
    return document.data();
}
