import { buildCollection, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";

type directorMessage = {
    name: string
    designation: string
    photo: string
    message: string
    userType: string
    community: EntityReference
    communityCalyxId: number
    department: EntityReference
    departmentCalyxId: number
    user: string
    expiresOn: Date
    createdAt: Date
    updatedAt: Date
}

export const directorMessageCollection = buildCollection<directorMessage>({
    name: "Newsletter Director Messages",
    singularName: "Email Digest Director Message",
    path: "cms_newsletter_director_messages",
    icon: "Email",
    properties: {
        name: {
            name: "Name",
            description: "Director's Name",
            validation: { required: true },
            dataType: "string"
        },
        designation: {
            name: "Designation",
            description: "Director's Designation",
            validation: { required: false },
            dataType: "string"
        },
        photo: {
            name: "Photo",
            dataType: "string",
            storage: {
                storagePath: "cms/email_digest_and_newsletter/director_photos/",
                acceptedFiles: ["image/*"]
            }
        },
        message: {
            name: "Message",
            validation: { required: true },
            dataType: "string",
            multiline: true
        },
        userType: {
            name: "User Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
                student: "Student",
                alumni: "Alumni",
                faculty: "Faculty",
                tpo: "Training And Placements",
                management: "Management",
                all: "All"
            },
            defaultValue: "Both"
        },
        community: {
            dataType: "reference",
            name: "Community",
            path: "communities",
            validation: { required: true },
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        department: {
            dataType: "reference",
            name: "Department",
            path: "departments"
        },
        departmentCalyxId: {
            name: "Department Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        user: () => {
            const user = getCurrentUser();
            return ({
                name: "Created  or Updated By User",
                dataType: "string",
                disabled: {
                    hidden: true
                },
                defaultValue: user?.email?.toString(),
            });
        },
        expiresOn: {
            name: "Expires on",
            dataType: "date"
        },
        createdAt: {
            name: "Created at",
            dataType: "date",
            disabled: true
        },
        updatedAt: {
            name: "Updated at",
            dataType: "date",
            disabled: true
        }
    },
    group: "Newsletter",
    callbacks: commonCallbacks
});
