import { buildCollection, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";

type headerFooter = {
    headerImage: string
    footerImage: string
    footerText: string
    community: EntityReference
    communityCalyxId: number
    department: EntityReference
    departmentCalyxId: number
    user: string
    createdAt: Date
    updatedAt: Date
}

export const headerFooterCollection = buildCollection<headerFooter>({
    name: "Email Digest Header Footers",
    singularName: "Email Digest Header Footer",
    path: "cms_email_digest_header_footer",
    icon: "Email",
    properties: {
        headerImage: {
            name: "Header Image",
            dataType: "string",
            description: "Image to show in the header section",
            storage: {
                storagePath: "cms/email_digest_and_newsletters/header_footer_images/",
                acceptedFiles: ["image/*"]
            }
        },
        footerImage: {
            name: "Footer Image",
            dataType: "string",
            description: "Image to show in the footer section",
            storage: {
                storagePath: "cms/email_digest_and_newsletters/header_footer_images/",
                acceptedFiles: ["image/*"]
            }
        },
        footerText: {
            name: "Footer Text",
            dataType: "string",
            description: "Text to show in the footer section below the footer image",
            multiline: true,
        },
        community: {
            dataType: "reference",
            name: "Community",
            path: "communities",
            validation: { required: true },
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        department: {
            dataType: "reference",
            name: "Department",
            path: "departments"
        },
        departmentCalyxId: {
            name: "Department Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        user: () => {
            const user = getCurrentUser();
            return ({
                name: "Created  or Updated By User",
                dataType: "string",
                disabled: {
                    hidden: true
                },
                defaultValue: user?.email?.toString(),
            });
        },
        createdAt: {
            name: "Created at",
            dataType: "date",
            disabled: true
        },
        updatedAt: {
            name: "Updated at",
            dataType: "date",
            disabled: true
        }
    },
    group: "Email Digest",
    callbacks: commonCallbacks
});
