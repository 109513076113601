import { buildCollection, EntityReference } from "firecms";

type Department = {
    name: string,
    community: EntityReference,
    communityCalyxId: number
    calyxId: number
}

export const departmentCollection = buildCollection<Department>({
    name: "Departments",
    singularName: "Department",
    path: "departments",
    permissions: ()  => ({
        edit: false,
        create: false,
        delete: false
    }),
    properties: {
        name: {
            name: "Name",
            description: "Department Name",
            validation: { required: true },
            dataType: "string"
        },
        community: {
            dataType: "reference",
            name: "College",
            path: "communities"
        },
        communityCalyxId: {
            name: "Community Calyx Id",
            dataType: "number",
            disabled: {
                hidden: true
            }
        },
        calyxId: {
            name: "Calyx ID",
            disabled: { hidden: true },
            dataType: "number"
        }
    },
    hideFromNavigation: true
});
