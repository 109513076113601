import { communityCollection } from "./communities"
import { departmentCollection } from "./departments";
import { directorMessageCollection } from "./newsletter/directorMessages"
import { hackathonCollection } from "./events/hackathon"
import { webinarCollection } from "./events/webinar"
import { headerFooterCollection } from "./emailDigest/headerFooter";
import { thankYouNoteCollection } from "./emailDigest/thankYouNote";
import { homePageCollection } from "./pages/home";
import { CDELandingPageCollection } from "./pages/CDELandingPage";

export function setDefaultFilters(communityId: number) {
    communityCollection.forceFilter = { calyxId: ["==", communityId] }
    departmentCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    directorMessageCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    headerFooterCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    thankYouNoteCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    hackathonCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    homePageCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    CDELandingPageCollection.forceFilter = { communityCalyxId: ["==", communityId] }
    webinarCollection.forceFilter = { communityCalyxId: ["==", communityId] }
}
