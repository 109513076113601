import React, { useCallback, useEffect, useState } from "react";
import { Authenticator, EntityCollection, FirebaseCMSApp } from "firecms";
import { User } from "firebase/auth";

import { CircularProgress } from "@mui/material";

import firebaseConfig from "./firebase/firebase-creds.json";
import { getCommunityData, signIn } from "./firebase/firebase";
import { setDefaultFilters } from "./collections/init";
import { communityCollection } from "./collections/communities";
import { departmentCollection } from "./collections/departments";
import { directorMessageCollection } from "./collections/newsletter/directorMessages";
import { thankYouNoteCollection } from "./collections/emailDigest/thankYouNote";
import { headerFooterCollection } from "./collections/emailDigest/headerFooter";
import { hackathonCollection } from "./collections/events/hackathon";
import { webinarCollection } from "./collections/events/webinar"
import { homePageCollection } from "./collections/pages/home";
import { CDELandingPageCollection } from "./collections/pages/CDELandingPage";

export default function App() {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const [loading, setLoading] = useState(true);
    const [collections, setCollections] = useState<Array<EntityCollection>>();

    async function setup(communityId: number) {
        setDefaultFilters(communityId);
        try {
            const communityData = await getCommunityData(communityId);
            const enabledCollections: Array<EntityCollection> = [communityCollection, homePageCollection];
            if (communityData.type === "College") {
                enabledCollections.push(...[departmentCollection, directorMessageCollection, thankYouNoteCollection, headerFooterCollection, CDELandingPageCollection])
            }
            if (communityData.features.includes("Hackathon")) {
                enabledCollections.push(hackathonCollection);
            }
            if (communityData.features.includes("Webinar")) {
                enabledCollections.push(webinarCollection);
            }
            setCollections(enabledCollections);
        } catch (e) {
            //    Ignore for now
        }
    }

    useEffect(() => {
        async function login() {
            let communityId;
            if (token) {
                const creds = await signIn(token);
                const user = creds.user;
                const idTokenResult = await user.getIdTokenResult();
                if (idTokenResult.claims.communityId) {
                    const communityId = idTokenResult.claims.communityId
                    localStorage.setItem('communityId', communityId);
                    window.location.search = "";
                }
            } else {
                communityId = localStorage.getItem('communityId');
                if (communityId) {
                    await setup(Number(communityId));
                }
            }
            setLoading(false);
        }

        login();
    }, [token]);

    const myAuthenticator: Authenticator<User> = useCallback(
        async ({
                   user
               }) => {
            if (user) {
                const idTokenResult = await user.getIdTokenResult();
                if (idTokenResult.claims.admin && idTokenResult.claims.communityId) {
                    return true;
                }
                if (idTokenResult.claims.superAdmin) {
                    return true;
                }
            }
            return false;
        }, []);
    return (
        loading ?
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress size={100}/>
            </div> :
            <FirebaseCMSApp
                name={"POD CMS"}
                logo={"/pod-logo.svg"}
                authentication={myAuthenticator}
                collections={collections}
                firebaseConfig={firebaseConfig}
                signInOptions={[]}
                primaryColor={"#5D21D1"}
                secondaryColor={"#FF4869"}
            />
    );
}

