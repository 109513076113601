import { buildCollection } from "firecms";

type Community = {
    name: string,
    calyxId: number
    subDomain: string
    maskedSubDomain: string
}
export const communityCollection = buildCollection<Community>({
    name: "Communities",
    singularName: "Community",
    path: "communities",
    permissions: () => ({
        edit: false,
        create: false,
        delete: false
    }),
    properties: {
        name: {
            name: "Name",
            description: "Community Name",
            dataType: "string"
        },
        subDomain: {
            name: "Sub Domain",
            description: "Community Sub Domain",
            dataType: "string"
        },
        maskedSubDomain: {
            name: "Masked Sub Domain",
            description: "Community Masked Sub Domain",
            dataType: "string"
        },
        calyxId: {
            name: "Calyx ID",
            disabled: { hidden: true },
            dataType: "number"
        },
    },
    hideFromNavigation: true,
});
