import { buildCollection, buildProperty, EntityReference } from "firecms";
import { commonCallbacks } from "../commonCallbacks";
import { getCurrentUser } from "../../firebase/firebase";

type tabs = {
  name: string;
  content: string;
  slug: string;
};

type organiserOrSponsor = {
  name: string;
  website: string;
  description: string;
  logo: string;
  type: string;
};

type webinar = {
  banner: string;
  landingPageBanner: string;
  landingPageMobileBanner: string;
  calyxId: number;
  community: EntityReference;
  communityCalyxId: number;
  createdAt: Date;
  organisers: Array<organiserOrSponsor>;
  slug: string;
  sponsors: Array<organiserOrSponsor>;
  tabs: Array<tabs>;
  updatedAt: Date;
  user: string;
};

export const webinarCollection = buildCollection<webinar>({
  name: "Webinars",
  singularName: "Webinar",
  path: "cms_events_webinar",
  icon: "EmojiEvents",
  permissions: () => ({
    edit: true,
    create: false,
    delete: false,
  }),
  properties: {
    banner: {
      name: "Banner Image (Aspect Ratio 1:1)",
      description:
        "Provide an image with resolution of 880x880 for better clarity.",
      dataType: "string",
      storage: {
        storagePath: "cms/events/webinar/",
        acceptedFiles: ["image/*"],
      },
    },
    landingPageBanner: {
      name: "Landing Page Banner (4:1 Aspect Ratio)",
      description:
        "Provide an image with minimum resolution of 1920x480 for better clarity.",
      dataType: "string",
      storage: {
        storagePath: "cms/events/webinar/",
        acceptedFiles: ["image/*"],
      },
    },
    landingPageMobileBanner: {
      name: "Landing Page Mobile Banner (2:1 Aspect Ratio)",
      description:
        "Provide an image with resolution of 800x400 for better clarity.",
      dataType: "string",
      storage: {
        storagePath: "cms/events/webinar/",
        acceptedFiles: ["image/*"],
      },
    },
    calyxId: {
      name: "Calyx Webinar ID",
      disabled: true,
      dataType: "number",
    },
    community: {
      dataType: "reference",
      name: "Community",
      path: "communities",
      validation: { required: true },
    },
    communityCalyxId: {
      name: "Community Calyx Id",
      dataType: "number",
      disabled: {
        hidden: true,
      },
    },
    createdAt: {
      name: "Created at",
      dataType: "date",
      disabled: true,
    },
    organisers: buildProperty({
      dataType: "array",
      name: "Organisers",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
          },
          website: {
            dataType: "string",
            name: "Website",
            url: true,
          },
          description: {
            name: "Description",
            dataType: "string",
          },
          logo: {
            name: "Logo",
            dataType: "string",
            storage: {
              storagePath: "cms/events/webinar/",
              acceptedFiles: ["image/*"],
            },
          },
          type: {
            name: "Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
              individual: "Individual",
              organisation: "Organisation",
            },
          },
        },
      },
    }),
    slug: {
      name: "Url slug",
      disabled: true,
      dataType: "string",
    },
    sponsors: buildProperty({
      dataType: "array",
      name: "Sponsors",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Name",
            dataType: "string",
            validation: { required: true },
          },
          website: {
            dataType: "string",
            name: "Website",
            url: true,
          },
          description: {
            name: "Description",
            dataType: "string",
          },
          logo: {
            name: "Logo",
            dataType: "string",
            storage: {
              storagePath: "cms/events/webinar/",
              acceptedFiles: ["image/*"],
            },
          },
          type: {
            name: "Type",
            validation: { required: true },
            dataType: "string",
            enumValues: {
              individual: "Individual",
              organisation: "Organisation",
            },
          },
        },
      },
    }),
    tabs: buildProperty({
      dataType: "array",
      name: "Tabs",
      of: {
        dataType: "map",
        properties: {
          name: {
            name: "Tab Name",
            dataType: "string",
            validation: { required: true },
          },
          content: {
            name: "Content",
            dataType: "string",
            markdown: true,
            validation: { required: true },
          },
          slug: {
            name: "Slug",
            dataType: "string",
            validation: { required: true },
          },
        },
      },
      expanded: true,
    }),
    updatedAt: {
      name: "Updated at",
      dataType: "date",
      disabled: true,
    },
    user: () => {
      const user = getCurrentUser();
      return {
        name: "Created or Updated By User",
        dataType: "string",
        disabled: {
          hidden: true,
        },
        defaultValue: user?.email?.toString(),
      };
    },
  },
  group: "Events",
  callbacks: commonCallbacks,
});
